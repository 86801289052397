





<script>

/**
 * Agent Tax Info component
 */


import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";




export default {


    props: {
        agent: {
            type: Object,
            default: () => ({
                fields: {
                    tax: {}
                }
            })
        },

        tax : {
            type: Object,
            default: () => ({
            })
        }
    },
    components: {
        flatPickr
    },


    data() {

        return {
            
        }

    },

    validations() {

        return {
            agent: {
               
            }
        }

    },

    methods: {
        update_agent() {
           
        },
      
    },

    created() {
        

    },

    mounted() {
    
    }

};
</script>


<template>
    <div class="row">

        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Tax Personel Info</h5>
                </div>
            </div>
            <div class="card-body">
                <div>
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">First Name</label>
                                <input type="text" class="form-control" v-model="tax.first_name"   placeholder="Enter Legal First Name"  />
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-designation-input">Last Name</label>
                                <input type="text" class="form-control"   placeholder="Enter Legal Last Name" v-model="tax.last_name"  />
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" >GST #</label>
                                <input type="text" class="form-control" v-model="tax.gst_number"   placeholder="Enter GST Number"  />
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" >SIN/ITN</label>
                                <input type="text" class="form-control" v-model="tax.sin_itn"   placeholder="Enter SIN / ITN"  />
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label">Is P.R.E.C</label>
                                <input type="checkbox" class="form-check-input" @change="tax.is_prec = $event.target.checked? '1': '0'" :checked="tax.is_prec == '1' ? true: false" />
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label" >Unit</label>
                                <input type="text" class="form-control" v-model="tax.unit" 
                                    placeholder="Enter Unit"  />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label" >Street Address</label>
                                <input type="text" class="form-control"   placeholder="Enter Address" v-model="tax.address"  />
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label" >City</label>
                                <input type="text" class="form-control" v-model="tax.city" 
                                    placeholder="Enter City"  />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label" >Province</label>
                                <input type="text" class="form-control" id="gen-info-designation-input"
                                    placeholder="Enter Province" v-model="tax.province" maxlength="2" />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label" >Postal Code</label>
                                <input type="text" class="form-control"  placeholder="Enter Postal code" v-model="tax.postal_code" v-mask="'NNN NNN'" />
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
        <!-- end card -->

        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            02
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">PREC Info</h5>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="contact-info-licence-number-input">Corporate Legal Name:</label>
                            <input type="text" class="form-control"  v-model="tax.prec_name"  placeholder="P.R.E.C Name"  :disabled="tax.is_prec == '0'"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="contact-info-phone-input">GST Number :</label>
                            <input type="text" class="form-control" v-model="tax.prec_itn" :disabled="tax.is_prec == '0'"
                                placeholder="GST Number" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-md-0">
                            <label  class="form-label">Corporate Date</label>
                            <flat-pickr v-model="tax.prec_date" placeholder="Select a date"  :disabled="tax.is_prec == '0' ? true : false"
                                class="form-control"></flat-pickr>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="mb-md-0">
                            <label  class="form-label">UnIncorporated Date</label>
                            <flat-pickr v-model="tax.in_prec_date" placeholder="Select a date"  :disabled="tax.is_prec == '1' ? true : false"
                                class="form-control"></flat-pickr>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
        <!-- end card -->

    </div>
</template>