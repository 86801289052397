





<script>

/**
 * Agent Team Tab component
 */
 import flatPickr from "vue-flatpickr-component";
 import "flatpickr/dist/flatpickr.css";
 import Choices from 'choices.js';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


import { required } from "vuelidate/lib/validators";
import { search_teams } from '@/api/misc'
export default {


    props: {
        agent: {
            type: Object,
            default: () => ({
                fields: {
                    basic: {}
                },
                team_flag : '0'
            })
        },

        
    },
    components: {
        flatPickr,
        VueBootstrapTypeahead,
    },


    data() {

        return {
            team_choice : {},
            team_name : '',
            team_data : [],

            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "m/d/Y",
                altFormat: "m/d/Y",
                ariaDateFormat: "m/d/Y",
                allowInput : true
            },
        }

    },

    validations() {
        if (this.agent.team_flag =='2') {
            return {
                team_name : {required}
            }
        } else {
            return {
                team_name : {}
            }
        }

    },

    methods: {
        onSelectedTeam(evt) {
            this.agent.team_id = evt.id
        },
        update_agent(new_agent) {
            this.agent.team_flag = new_agent.team_flag

            let team_option = [
                {label : 'No Team', value : '0' , selected : this.agent.team_flag == '0' ? true : false},
                {label : 'Team Member', value : '1', selected : this.agent.team_flag == '1' ? true : false},
                {label : 'Team Leader', value : '2', selected : this.agent.team_flag == '2' ? true : false},
            ]
            this.team_choice.clearStore()
            this.team_choice.setChoices(team_option)
           
        },
      
        reset() {
            this.$v.$reset();
        },

        validate() {
            this.$v.$touch();
            return this.$v.$invalid
        },

        query_teams(str) {
            search_teams(str).then(res => {
                this.team_data = []
                res.data.map(e => {
                    e.name = e.branch_name
                    this.team_data.push(e)
                })
            })
        }

    },

    created() {
        

    },

    mounted() {
        let team_option = [
            {label : 'No Team', value : '0' , selected : this.agent.team_flag == '0' ? true : false},
            {label : 'Team Member', value : '1', selected : this.agent.team_flag == '1' ? true : false},
            {label : 'Team Leader', value : '2', selected : this.agent.team_flag == '2' ? true : false},
        ]

        this.team_choice = new Choices('#team_flag', {choices : team_option})
    },

    watch: {


        team_name(new_search_name) {
            this.query_teams(new_search_name)
        },



    },

};
</script>


<template>
    <div class="row">

        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Agent Team</h5>
                </div>
            </div>
            <div class="card-body">
                <div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Type:</label>
                                <select id="team_flag" v-model="agent.team_flag" class="form-control"></select>
                            </div>
                        </div>

                        <div class="col-md-2" v-if="agent.team_flag != '0' && agent.team_flag != undefined">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Start Date:</label>
                                <flat-pickr :config="dpconfig" v-model="agent.fields.basic.team_start_date" placeholder="Select a date" 
                                class="form-control"></flat-pickr>
                            </div>
                       
                        </div>

                        <div class="col-md-2"  v-if="agent.team_flag != '0' && agent.team_flag != undefined">
                            <div class="mb-3">
                                <label for="contact-info-email-input">End Date:</label>
                                <flat-pickr :config="dpconfig" v-model="agent.fields.basic.team_end_date" placeholder="Select a date"  
                                class="form-control"></flat-pickr>
                            </div>
                       
                        </div>
                      
                    </div>


                    <div class="row">
                        <div class="col-md-4"  :class="agent.team_flag == '1'?'d-block':'d-none' ">
                            <label class="form-label" >Team </label>
                            <vue-bootstrap-typeahead v-model="team_name" :remoteMatch="true"
                              :data="team_data" :serializer="s => s.name" :foramterDisplay="s => s.name" :ref="'team_name_ref'"
                              @hit="onSelectedTeam($event)" autocomplete="off" />

                            <div v-if="$v.team_name.$error" class="invalid-feedback">
                                <span v-if="!$v.team_name.required">This value is required.</span>
                            </div>
                        </div>

                        <div class="col-md-2"  :class="agent.team_flag == '1'?'d-block':'d-none' ">
                            <label>Award Portion(%)</label>
                            <input type="text" class="form-control" v-model="agent.team_award"  />
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!-- end card -->


    </div>
</template>