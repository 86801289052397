





<script>

/**
 * Agent Notes Tab component
 */

 import CKEditor from "@ckeditor/ckeditor5-vue";
 import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export default {


    props: {
        agent: {
            type: Object,
            default: () => ({
                description : '',
                fields: {
                    basic: {}
                }
            })
        },

        
    },
    components: {
        ckeditor: CKEditor.component
    },


    data() {

        return {
            editor: ClassicEditor,
        }

    },

    validations() {

        return {
            agent: {
               
            }
        }

    },

    methods: {
        update_agent() {
          
        },
      
    },

    created() {
        

    },

    mounted() {
        if (!this.agent.description) {
            this.agent.description = ''
        }
    }

};
</script>


<template>
    <div class="row">

        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Agent Notes</h5>
                </div>
            </div>
            <div class="card-body">
                <div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="mb-3">
                                <div class="mb-3">
                                    <ckeditor v-model="agent.description" :editor="editor"></ckeditor>
                                </div>
                            </div>
                        </div>
                      
                    </div>



                </div>
            </div>
        </div>
        <!-- end card -->


    </div>
</template>