





<script>

/**
 * Agent Financial Info component
 */

 import Choices from 'choices.js';



export default {



    props: {
        agent: {
            type: Object,
            default: () => ({
                fields: {
                    financial: {}
                }
            })
        },

        financial : {
            type: Object,
            default: () => ({
            })
        }
    },
    components: {
        
    },


    data() {

        return {
           charge_choice: {}
        }

    },

    compute: {

    },

    validations: {


        agent: {

        }


    },



    methods: {

      

        reset() {
            this.$v.$reset();
        },

        validate() {
            this.$v.$touch();
            return this.$v.$invalid
        },


        update_agent(agent) {
            this.agent.payment_type = agent.payment_type
            this.agent.charge_type = agent.charge_type
            this.update_choice();
        },


        update_choice() {
            let charge_type_arr = [
                {label : 'Cheque', value : 'CHEQUE', selected : this.agent.charge_type == 'CHEQUE' ? true : false},
                {label : 'PAD', value : 'PAD', selected : this.agent.charge_type == 'PAD' ? true : false},
                {label : 'Credit Card', value : 'CREDIT_CARD', selected : this.agent.charge_type == 'CREDIT_CARD' ? true : false},
                {label : 'E-Transfer', value : 'ETRANSFER', selected : this.agent.charge_type == 'ETRANSFER' ? true : false},
            ]

        
            if (this.charge_choice) {
                this.charge_choice.clearStore()
                this.charge_choice.setChoices(charge_type_arr)
            }

            let payment_type_arr = [
                {label : 'Cheque', value : 'CHEQUE' , selected : this.agent.payment_type == 'CHEQUE' ? true : false},
                {label : 'Direct Deposit', value : 'EFT', selected : this.agent.payment_type == 'EFT' ? true : false},
            ]
        
           
            if (this.payment_choice) {
                this.payment_choice.clearStore()
                this.payment_choice.setChoices(payment_type_arr)
            }
        }

    },

    created() {



    },

    mounted() {
        this.charge_choice = new Choices('#charge_type',{});
        this.payment_choice = new Choices('#payment_type',{});
       
        this.update_choice();
    }

};
</script>


<template>
    <div class="row">

        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Payment Type for Agent Billing</h5>
                </div>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="contact-info-email-input">Type:</label>
                            <select id="charge_type" v-model="agent.charge_type" class="form-control"></select>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="agent.charge_type == 'PAD'">
                    <div class="row" >
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Bank Name:</label>
                                <input type="text"  class="form-control" v-model="financial.charge_bank_name" 
                                    />
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Financial Institute #:</label>
                                <input type="text"  class="form-control" v-model="financial.charge_institute_number" maxlength="5"
                                    />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Transit Number #:</label>
                                <input type="text"  class="form-control" v-model="financial.charge_transit_number"  maxlength="3"
                                    />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Account Number #:</label>
                                <input type="text"  class="form-control" v-model="financial.charge_account_number" 
                                    />
                            </div>
                        </div>
                    </div>
                </div>


                <!-- credit card-->
                <div class="row" v-if="agent.charge_type == 'CREDIT_CARD'">
                
                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Card Type :</label>
                                <select id="credit_type" v-model="financial.charge_credit_card_type" class="form-control">
                                    <option value="VISA">VISA</option>
                                    <option value="MASTERCARD">MASTERCARD</option>
                                    <option value="AMEX">AMEX</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Name on Card :</label>
                                <input type="text"  class="form-control" v-model="financial.charge_credit_card_name" />
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Credit Card Number :</label>
                                <input type="text"  class="form-control" v-model="financial.charge_credit_card_number" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Expiration Date :</label>
                                <input type="text"  class="form-control" v-model="financial.charge_credit_card_expiry_date" placeholder="MMYYYY" maxlength="6"/>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">CVC :</label>
                                <input type="text"  class="form-control" v-model="financial.charge_credit_card_cvc" maxlength="5"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end card -->


        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            02
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Deposit  Type for Agent Commission</h5>
                </div>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="contact-info-email-input">Type:</label>
                            <select id="payment_type" v-model="agent.payment_type" class="form-control"></select>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="agent.payment_type == 'EFT'">
                    <div class="row" >
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Bank Account Name:</label>
                                <input type="text"  class="form-control" v-model="financial.pay_bank_name" 
                                    />
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Institutional Number #:</label>
                                <input type="text"  class="form-control" v-model="financial.pay_institute_number"  maxlength="5"
                                    />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Transit Number #:</label>
                                <input type="text"  class="form-control" v-model="financial.pay_transit_number"  maxlength="3"
                                    />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="contact-info-email-input">Account Number #:</label>
                                <input type="text"  class="form-control" v-model="financial.pay_account_number" 
                                    />
                            </div>
                        </div>
                    </div>

                </div>
 

            </div>
        </div>
        <!-- end card -->


    </div>
</template>